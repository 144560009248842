.mat-mdc-checkbox {

  .mdc-form-field {

    .mdc-checkbox {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      padding: 0 !important;

      .mdc-checkbox__native-control {
        width: 20px;
        height: 20px
      }

      .mdc-label {
        padding: 0 !important;
      }

      .mat-mdc-checkbox-ripple {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
      }

      .mdc-checkbox__background {
        margin: 0;
        width: 16px;
        height: 16px;
        position: relative;
        top: unset !important;
        left: unset !important;

        .mdc-checkbox__checkmark {
          color: white !important;
        }
      }
    }
  }

  .mdc-form-field > label {
    padding: 0 !important;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          display: flex;
          background-color: #2f9bbf !important;
          border-color: #2f9bbf !important;
        }
      }
    }
  }

  &.asset-select-checkbox {
    position: absolute;
    top: 20px;
    right: 20px;

    .mdc-form-field {
      .mdc-checkbox {
        width: 30px;
        height: 30px;
        flex: unset;
        padding: 0 !important;
        margin-right: 8px;

        .mdc-checkbox__native-control {
          width: 30px;
          height: 30px;
        }

        .mdc-checkbox__background {
          display: flex;
          border-color: rgba(0, 0, 0, 0.54);
          top: unset;
          left: unset;
          width: 30px;
          height: 30px;
          border-color: rgb(0 0 0);
          background: #fff !important;
        }
      }
    }

    &.mat-mdc-checkbox-checked {
      .mdc-form-field {
        .mdc-checkbox {
          .mdc-checkbox__background {
            display: flex;
            background-color: #2f9bbf !important;
            border-color: #2f9bbf !important;
          }
        }
      }
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    background-color: white;
    border-color: rgba(0, 0, 0, 0.54);
  }

  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: rgba(0, 0, 0, 0.54);
  }

}

.invoice-agent-label {
  .mdc-form-field > label {
    width: 85px;
  }
}

#free-job-checkbox {
  margin-right: 10px;
  padding: 10px !important;

  .mdc-form-field > label {
    width: 60px;
  }

  .mdc-form-field {
    .mdc-checkbox {
      margin-right: 16px;
    }
  }
}

.mdc-checkbox__ripple {
  display: none !important;
}
