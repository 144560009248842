// ========= IMPORTS / INCLUDES ========== //
@use '@angular/material' as mat;


// ========= Colour Palette ========== //

@include mat.all-component-typographies();
@include mat.core();

$primary: mat.define-palette(mat.$light-blue-palette, 600);
$accent: mat.define-palette(mat.$green-palette, A400);
$warn: mat.define-palette(mat.$red-palette, 400);

$theme: mat.define-light-theme((
        color: (
                primary: $primary,
                accent: $accent,
                warn: $warn
        )
));

@include mat.all-component-themes($theme);

$blue: #2f9bbf;
$green: #1bcd6b;
$red: #dc5a5a;
$orange: #ff7f00;
$black: #333333;
$grey: #555555;
$border: #d5d5d5;
$border-light: #eeeeee;
$off-white: #f8f8f8;
$off-white-blue: #eff7fa;
$white: #ffffff;

// ========= Fonts ========== //

$prism-typography: mat.define-typography-config(
        $font-family: "Nunito Sans, Roboto, Helvetica Neue, sans-serif",
        $headline-5: mat.define-typography-level(22px, 30px, 400, $letter-spacing: 0),
        $body-2: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0),
        $body-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0)
);

@include mat.all-component-typographies($prism-typography);

// ========= Global Imports ========== //

@import "styles/base";
@import "styles/typography";
@import "styles/button";
@import "styles/form";
@import "styles/modal";
@import "styles/content";
@import "styles/table";
@import "styles/button-loader";
@import "styles/click";
@import "styles/accordion";
@import "styles/tooltip";
@import "styles/checkbox";
@import "styles/snackbar";

// POPPER ARROW
.ngxp__container .ngxp__arrow {
  display: none;
}

// Snackbar css specificity override
::ng-deep .dl-snack {
  .mat-mdc-simple-snack-bar {
    .mat-mdc-snack-bar-action {
      .mat-mdc-button.mat-mdc-button-base {
        background: #2f9bc0 !important;
        color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 602px) {
  ::ng-deep #agent-service-dialog {
    position: fixed;
    top: 2rem;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 420px) {
  ::ng-deep #agent-service-dialog {
    height: 68vh;
  }
  ::ng-deep #agent-download-dialog {
    width: 69%;
    margin: auto;
  }
}

@media screen and (max-width: 375px) {
  ::ng-deep #agent-service-dialog {
    height: 68vh;
  }
  ::ng-deep #agent-download-dialog {
    width: 51%;
    margin: auto;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {

}

@media screen and (max-device-width: 812px) {
  .cdk-overlay-container .cdk-overlay-pane {
    height: auto;
    width: auto !important;
    max-height: 90vh;
    min-width: 313px !important;
    justify-content: flex-end;
    margin-top: 7px;
  }
}
