.mat-mdc-tooltip.icon-tooltip {
  font-size: 15px !important;
}

.cdk-overlay-pane {

  &.mat-mdc-tooltip-panel-right {
    margin-top: 0 !important;
    justify-content: flex-start;
  }
  &.mat-mdc-tooltip-panel-left {
    margin-top: 0 !important;
    justify-content: flex-end;
  }
}

.tooltip {
  &:first-child {
    min-width: 150px;
    text-align: center;
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .mdc-tooltip__surface {
    background-color: #f6f6f6;
    color: #555;
    text-align: center;
    font-size: 14px;
    letter-spacing: normal;
  }
}