// ========= Content Styles ========== //

.content-wrapper {
  .page-title {
    margin: 0;
    font-size: 28px;

    .job-detail-id {
      font-weight: 600;
    }
  }

  .toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;

    .toolbar-left {
      .search-field {
        display: inline-block;
        min-width: 200px;
        margin: {
          bottom: 0;
        }

        .mat-mdc-form-field {
            .mat-mdc-form-field-flex {
              padding: 0 40px 0 18px;
              border-radius: 50px;
              background: #fff;
              .mat-mdc-form-field-infix {
                width: 160px;
                min-height: fit-content;
                padding: 0;

                img {
                  position: absolute;
                  right: -24px;
                  top: 12px;
                  opacity: .8;
                }
              }
            }
        }
      }
    }

    .toolbar-right {
      display: flex;
      align-items: flex-start;

      .edit-job-btn {
        margin-right: 20px;
      }

      .btn {
        height: 40px;
      }

      .select-service-field {
        margin: {
          right: 12px;
          bottom: 0;
        }

        .mat-mdc-form-field {
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-wrapper {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.jobs-toolbar-item {
  height: auto;
}