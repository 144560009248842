// ========= Core Styles ========== //

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 16px 0;
  letter-spacing: normal;
}

h1,
h2,
h3 {
  font-weight: 300;
}

h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
}

p {
  color: $black;
}