// ========= Form Styles ========== //

.btn {
  &.mat-mdc-raised-button,
  &.mat-mdc-button {
    height: 40px;
    display: inline-block;
    min-width: 100px;
    padding: 0 24px 0;
    line-height: 40px;
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: normal;
    font-weight: 800;
    z-index: 0;

    .mdc-button__label {
      height: inherit;
      margin: 0 auto;
      letter-spacing: normal;
    }
  }

  &.mat-mdc-raised-button {
    background: $blue;
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .11);

    &:not([class*=mat-elevation-z]) {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
    }

    &:hover {
      box-shadow: 0 2px 3px rgba(0, 0, 0, .17);
    }

    &.btn-green {
      background: $green;
    }

    &.btn-red {
      background: $red;
    }

    &.btn-blue {
      background: rgb(47, 155, 191);
    }

    .mdc-button__label {
      width: max-content;
      display: flex;
      align-items: center;
      color: $white;
      white-space: nowrap;
      font: {
        size: 14px;
        weight: 600;
      }
    }
  }

  &.mat-mdc-outlined-button {
    &.btn-blue {
      color: $blue;
      border-color: $blue;
    }
  }

  &.mat-mdc-button {
    .mat-ripple,
    .mat-button-focus-overlay {
      display: none;
    }

    .mdc-button__label {
      color: $blue;
      width: max-content;
      font: {
        size: 14px;
        weight: 600;
      }
    }

    &:hover {
      background: none;

      .mdc-button__label {
        text-decoration: underline;
      }
    }

    &.btn-green {
      .mdc-button__label {
        color: $green;
      }
    }

    &.btn-red {
      .mdc-button__label {
        color: $red;
      }

      .mat-mdc-button-persistent-ripple {
        display: none;
      }

      .mat-mdc-button-ripple {
        display: none;
      }

    }

    &.mat-warm {
      .mat-mdc-button-persistent-ripple {
        display: none;
      }
    }
  }

  &[disabled] {
    box-shadow: none !important;
    opacity: .3;
  }

  &.btn-outline {
    background: none !important;
    border: 1px solid $blue;
    box-shadow: none !important;
    line-height: 38px;

    .mdc-button__label {
      color: $blue;
    }

    &.btn-green {
      border: 1px solid $green;
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
      .mdc-button__label {
        color: $green;
      }
    }

    &.btn-red {
      border: 1px solid $red;
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
      .mdc-button__label {
        color: $red;
      }
    }

    &.btn-blue {
      border: 1px solid $blue;

      .mdc-button__label {
        color: $blue;
      }
    }
  }

  &.btn-link {
    &:hover {
      .mdc-button__label {
        text-decoration: underline;
      }
    }

    .mat-mdc-button-ripple,
    .mat-button-focus-overlay {
      display: none;
    }

    .mdc-button__label {
      font: {
        size: 14px !important;
      }
    }
  }

  &.btn-small {
    min-width: 80px;
    line-height: 34px;

    .mdc-button__label {
      font-size: 14px;
    }
  }

  &.btn-nav {
    width: fit-content;
    margin: {
      left: -24px;
      top: -12px;
    }

    .mat-mdc-button-ripple {
      display: none;
    }

    .mdc-button__label {
      display: flex;
      padding-left: 0;

      img {
        margin-right: 8px;
      }

      span {
        font: {
          size: 14px;
          weight: 400;
        }
      }
    }
  }

  &.btn-bin {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.mat-mdc-icon-button {
  &.share-album-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;

    .mat-mdc-button-persistent-ripple {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px !important;
      height: 45px !important;
    }

    .mat-mdc-button-ripple {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px !important;
      height: 45px !important;
    }

    svg {
      width: 35px !important;
      height: 35px !important;
    }
  }

  &.invoice-album-btn {
    .mat-mdc-button-persistent-ripple {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px !important;
      height: 45px !important;
    }

    .mat-mdc-button-ripple {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 45px !important;
      height: 45px !important;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;

    img {
      width: 35px !important;
      height: 35px !important;
    }
  }

  &.table-btn {
    margin: 0 auto;

    &.details-btn,
    &.invoice-btn,
    &.album-btn {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    &.share-btn {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    &.mat-mdc-button-base {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &.jobs-detail-table-btn {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 54px;
    justify-content: center;
    height: 54px;

    .mdc-button__label {
      height: 30px;
      width: 30px;
    }

    &.album-btn {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.filter-btn {
    padding: 0;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-around;
  }

  &.remove-file-item {
    padding: 0 0 0 12px !important;

    .mat-mdc-button-persistent-ripple {
      display: none;
      align-items: center;
      justify-content: center;
      width: 45px !important;
      height: 45px !important;
    }

    .mat-mdc-button-ripple {
      display: none;
    }

    .mat-mdc-button-touch-target {
      display: none;
    }

    .mat-mdc-focus-indicator {
      display: none;
    }
  }
}

.mat-button-toggle-group {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .14) !important;

  .mat-button-toggle {
    color: #555;

    &.mat-button-toggle-checked {
      background: #EFF7FA;
      color: $blue;
    }

    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        height: 40px;
        padding: 0 32px;
        line-height: 40px;
        font-size: 13px;
      }
    }
  }
}

.btn-link {
  height: auto !important;

  .mdc-button__ripple {
    display: none;
  }

  .mdc-button__label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 8px 16px;

    font: {
      size: 14px;
      weight: 600;
    }

    svg {
      margin-right: 8px;
    }

    span {
      display: inline-block;
      margin-top: 1px;
    }

  }

  .mat-mdc-button-ripple {
    display: none;
  }

  &.manual-address-btn, &.look-up-btn {
    .mdc-button__label {
      padding: 0;
    }
  }
}

.mat-expansion-indicator::after {
  height: 20px;
  width: 20px;
  padding: 0 !important;
}

svg-icon {
  display: flex;
  align-items: center;
}

.xero-logo:hover {
  cursor: pointer !important;
}

.cancel-btn.btn-red {
  background: none;
  border: 1px solid #dd5d5d !important;
  box-shadow: none;
  padding: 0 15px !important;
  float: right;
}

.brief-warn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
}