// ========= Modal Styles ========== //

.cdk-overlay-container {
  .cdk-overlay-pane {
    height: auto;
    max-height: 100vh;
    justify-content: flex-end;
    margin-top: 7px;
    background: transparent;
    width: auto !important;
    min-width: 600px;

    .mat-mdc-dialog-container {
      padding: 0 !important;
      border-radius: 2px;
      overflow: hidden !important;

      .mat-mdc-dialog-title {
        font-size: 18px !important;
        border-bottom: 1px solid #eee;
        height: auto;
        line-height: 1;
        color: $black;
        padding: 20px 24px;
        margin: 0;
      }

      .mdc-dialog__title {
        &::before {
          height: auto;
          content: none;
        }

        line-height: 100% !important;
      }

      .mat-mdc-dialog-content,
      .mat-mdc-dialog-actions {
        padding: 20px !important;
        margin: 0;
      }

      .mat-mdc-dialog-content {
        flex-grow: 1;
        overflow-x: hidden;
      }

      .mat-mdc-dialog-actions {
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        height: 81px;
        position: relative;
        z-index: 3;
        box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.04);
        border-top: 1px solid #eee;
      }

      .form-field-label {
        letter-spacing: 0;
      }

      .mat-mdc-dialog-surface {
        background: transparent !important;
      }
    }
  }

  .mat-form-field-select {
    justify-content: unset !important;
  }

  .mobile-modal {
    width: 40%;
  }

  .cdk-overlay-dark-backdrop {
      background: rgba(0, 0, 0, 0.6);
  }

  .mat-mdc-option {
    display: flex;
    align-items: center;

    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      margin: 0;
    }
  }
}

prism-add-job-product {
  &.mat-mdc-dialog-container {
    visibility: hidden;
    background: transparent;
  }

  .mat-mdc-dialog-container {
    visibility: hidden;
  }

  .mat-mdc-dialog-content {
    display: flex;
  }
}


.add-job-product-dialog,
.form-upload-dialog,
.service-upload-dialog {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  max-width: unset !important;
  overflow-y: auto;
  min-width: unset !important;

  .mat-mdc-dialog-container {
    width: 100%;
    pointer-events: none;
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;

    prism-service-upload {
      pointer-events: auto;

      mat-mdc-card-actions {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .mat-mdc-dialog-content {
    flex-wrap: wrap;
    justify-content: center;
    max-height: 100% !important;
  }

  prism-form-upload {
    visibility: visible;
    pointer-events: all;
  }

  .upload-modal {
    max-width: 480px;
    min-width: 480px;

    .mat-mdc-card-header-text {
      margin: 0;

      .mat-mdc-card-title {
        margin: 0;
      }
    }
  }
}


#select-option {
  display: flex;
  align-items: center;
  padding-right: 4px;
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    display: flex;
    align-items: center;
  }

  .service-name {
    margin-right: auto;
  }

  svg {
    max-width: 16px;
  }

  .num-input {
    width: 20px;
    pointer-events: none;
    min-width: 20px;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  width: calc(600px - 48px) !important;
  padding: {
    top: 0 !important;
    bottom: 0 !important;
  }
}

.mdc-dialog, .mdc-dialog__scrim {
  width: auto !important;
}

.mat-dialog-container {
  background: white;
}

.cdk-virtual-scroll-content-wrapper {
  position: relative !important;
}

.dz-image {
  display: none !important;
}

.dz-size {
  display: none !important;
}

.dz-message {
  pointer-events: none;
}

.dz-filename {
  display: none !important;
}

.dz-success-mark {
  display: none !important;
}

.dz-error-mark {
  display: none !important;
}

.dz-error-message {
  display: none !important;
}

.dz-progress {
  display: none !important;
}

.add-edit-job-container {
  .mat-mdc-dialog-content {
    max-height: 66vh;
  }
}