// ========= Table Styles ========== //

.mat-mdc-table {
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  .table-caption {
    font-size: 15px;
    color: $black;
  }

  .mat-mdc-header-row {
    height: 58px;

    .mat-mdc-header-cell {
      padding-top: 2px;
      color: rgba(0, 0, 0, 0.4);
      text-transform: uppercase;

      .mat-sort-header-button {
        text-transform: uppercase;
      }

      font: {
        size: 12px;
        weight: 500;
      }
    }
  }

  .mat-mdc-row {
    max-height: 76px;

    &:last-of-type {
      .mat-mdc-cell {
        border-bottom: none;
      }
    }

    .mat-mdc-cell {
      color: $grey;
      font-size: 14px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;

      &.mat-column-invoiced {
        color: $blue;
        font-weight: 600;
      }

      .table-data-text {
        display: flex;

      }
    }
  }

  &.invoice-products-table {
    .mat-mdc-header-cell {
      background-color: #f9f9f9;
    }
  }
}

.mat-mdc-paginator {
  margin-top: 8px;
  background: none;
}

.mat-mdc-row:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}