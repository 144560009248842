.mat-mdc-simple-snack-bar {
  .mat-mdc-snack-bar-actions {
    .mat-mdc-snack-bar-action {
      background-color: #2F9BBF;

      .mdc-button__label {
        color: white;
        letter-spacing: normal;
      }
    }
  }
}